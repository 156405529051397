.basket{
  clip-path: polygon(50% 0, 40% 5%, 100% 5%, 100% 100%, 0 100%, 0 5%, 60% 5%);
  transform: translateX(-75px);
}

@media(max-width: 640px){
  .basket{
    clip-path: polygon(21% 0, 31% 5%, 100% 5%, 100% 100%, 0 100%, 0 5%, 11% 5%);
    transform: translateX(0px);
  }
}

.loading-spinner{
  border: 4px solid #eee;
  border-right: 4px solid #009cde;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 1rem auto;

  animation: spin 1s linear infinite;
}

.fullscreen-cover{
  background: #0008;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
}

@keyframes spin {
  to{
    transform: rotateZ(360deg);
  }
}

.modal{
  background: #f8f8f8;
  border-radius: 10px;
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  padding: 1rem;
  box-shadow: 0 2px 10px #ddd;
  transition: opacity 300ms;
}

.modal-close{
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 1.75rem;
  cursor: pointer;
}

input, select, textarea{
  background: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 0.5rem;
}

.shipping input{
  margin-top: 0.75rem;
  margin-right: 0.5rem;
}

input:disabled, button:disabled, button:disabled:hover{
  background: #eee;
  cursor: not-allowed;
}
